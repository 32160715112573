import { render, staticRenderFns } from "./plan.vue?vue&type=template&id=23b9d75e&scoped=true"
import script from "./plan.vue?vue&type=script&lang=js"
export * from "./plan.vue?vue&type=script&lang=js"
import style0 from "./plan.vue?vue&type=style&index=0&id=23b9d75e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23b9d75e",
  null
  
)

export default component.exports